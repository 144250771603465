<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>

      <div class="splitTitle">{{ $t('sale.基本信息') }}</div>
      <van-collapse-item :title="$t('sale.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('sale.认领单号') }}</h5>
          <p>{{ this.ruleForm.code }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.认领日期') }}</h5>
          <p>{{ this.ruleForm.claimDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务员') }}</h5>
          <p>{{ this.ruleForm.salesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务部门') }}</h5>
          <p>{{ this.ruleForm.deptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.备注') }}</h5>
          <p>{{ this.ruleForm.remake }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.收款信息')" name="2">
        <div class="items">
          <h5>{{ $t('sale.账套') }}</h5>
          <p>{{ ruleFormRe.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.收款登记号') }}</h5>
          <p>{{ ruleFormRe.registrationNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.到账日期') }}</h5>
          <p>{{ ruleFormRe.receiptDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.收款方式') }}</h5>
          <p>{{ ruleFormRe.collectionMethod }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.收款账号') }}</h5>
          <p>{{ ruleFormRe.collectionAccountNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务部门') }}</h5>
          <p>{{ ruleFormRe.businessDepartmentName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.来款客户编码') }}</h5>
          <p>{{ ruleFormRe.customers }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.来款客户名称') }}</h5>
          <p>{{ ruleFormRe.customersName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.原币币种') }}</h5>
          <p>{{ ruleFormRe.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.原币金额') }}</h5>
          <p>{{ ruleFormRe.collectionCurrencyAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对') + functionalCurrency + $t('sale.汇率') }}</h5>
          <p>{{ ruleFormRe.exchangeRateRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对') + functionalCurrency + $t('sale.金额') }}</h5>
          <p>{{ ruleFormRe.rmbAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.认领状态') }}</h5>
          <p>{{ ruleFormRe.status | setDict('COLLECT_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.已认领金额') }}</h5>
          <p>{{ ruleFormRe.claimAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.未认领金额') }}</h5>
          <p>{{ ruleFormRe.noClaimAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.收款属性') }}</h5>
          <p>{{ ruleFormRe.collectionAttr | setDict('COLLECTION_ATTR') }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.收款明细表')" name="3">
        <div v-if="!ruleForm.saleSubscriptionDetailList || !ruleForm.saleSubscriptionDetailList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in ruleForm.saleSubscriptionDetailList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.收款类型') }}:</h5>
            <p>{{ item.collectionSubtype | setDict('SALE_COLLECTION_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.认领金额') }}:</h5>
            <p>{{ item.useAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.单据类型') }}:</h5>
            <p>{{ item.businessBillType | setDict('SALE_SUB_BILL_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.单据编号') }}:</h5>
            <p>{{ item.businessBillCode }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.发货单号') }}:</h5>
            <p>{{ item.deliveryNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.申请退税金额') }}:</h5>
            <p>{{ item.applyTaxRefundAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.对') + functionalCurrency + $t('sale.汇率') }}:</h5>
            <p>{{ item.exchangeRmbRate }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.对') + functionalCurrency + $t('sale.金额') }}:</h5>
            <p>{{ item.useAmountRmb | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.备注') }}:</h5>
            <p>{{ item.remake }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.最新记录') }}:</h5>
            <p>{{ item.currentRejectFlag | setDict('PUB_Y_N') }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.附件') }}</div>
      <van-collapse-item :title="$t('sale.附件信息')" name="4">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.系统信息') }}</div>
      <van-collapse-item :title="$t('sale.系统信息')" name="5">
        <div class="items">
          <h5>{{ $t('sale.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';

export default {
  name: 'visitorFieldComponent',
  components: {
    myHistoryPanel,
    myApproval
  },
  data() {
    return {
      attachmentList: [],
      options: [
        {
          value: '1',
          label: '支票',
          disabled: false
        },
        {
          value: '2',
          label: '电汇',
          disabled: false
        },
        {
          value: '3',
          label: '商业承兑汇票',
          disabled: false
        },
        {
          value: '4',
          label: '银行承兑汇票',
          disabled: false
        }
      ],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'saleDocClaim',
      processInstanceName: '销售认款',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      functionalCurrency: '',
      ruleForm: {
        id: '',
        createByName: '',
        lastmodifiedTime: '',
        saleSubscriptionDetailList: [],
        registrationId: '',
        salesMan: '',
        code: '',
        claimDate: '',
        remake: '',
        claimDept: '',
        isCancel: 'N',
        processInstanceId: ''
      },
      ruleFormRe: {
        registrationNo: '',
        receiptDate: '',
        collectionMethod: '',
        collectionAccountNumber: '',
        collectionAccountName: '',
        customers: '',
        customersName: '',
        currency: '',
        collectionCurrencyAmount: '',
        exchangeRateUsd: '',
        exchangeRateRmb: '',
        rmbAmount: '',
        usdAmount: '',
        status: '',
        noClaimAmount: ''
      }
    };
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      let _this = this;
      this.$myHttp({
        method: 'POST',
        url: '/microarch/sale/saleSubscriptionHeader/viewHistory',
        data: {
          entity: {
            'id': _this.ruleForm.id,
            'claimSource': _this.ruleForm.claimSource
          }
        }
      }).then(res => {
        // 成功回调方法
        var reData = res.data.domRe;
        var saleData = res.data.sale;
        if (reData) {
          _this.ruleForm.claimSource = reData.claimSource;
          _this.ruleFormRe.orgId = reData.orgId;
          _this.ruleForm.orgCode = reData.orgCode;
          _this.ruleFormRe.orgName = reData.orgName;
          _this.ruleFormRe.registrationNo = reData.registrationNo;
          _this.ruleFormRe.currency = reData.currency;
          _this.ruleFormRe.collectionCurrencyAmount = reData.collectionCurrencyAmount;
          _this.ruleFormRe.exchangeRateUsd = reData.exchangeRateUsd;
          _this.ruleFormRe.exchangeRateRmb = reData.exchangeRateRmb;
          _this.ruleFormRe.rmbAmount = reData.rmbAmount;
          _this.ruleFormRe.usdAmount = reData.usdAmount;
          _this.ruleFormRe.status = reData.status;
          _this.ruleFormRe.noClaimAmount = reData.noClaimAmount;
          _this.recordAmount = reData.noClaimAmount;
          _this.ruleFormRe.collectionMethod = reData.collectionMethod;
          _this.ruleForm.claimDept = reData.claimDept;
          _this.ruleFormRe.customersId = reData.customersId;
          _this.ruleFormRe.customers = reData.customers;
          _this.ruleFormRe.customersName = reData.customersName;
          _this.ruleFormRe.businessDepartmentId = reData.businessDepartmentId;
          _this.ruleFormRe.businessDepartmentName = reData.businessDepartmentName;
          if(reData.claimSource === '收款登记'){
            _this.ruleFormRe.isBillFlag = false;
            _this.ruleFormRe.receiptDate = reData.receiptDate;
            _this.ruleFormRe.collectionAccountNumber = reData.collectionAccountNumber;
            _this.ruleFormRe.collectionAttr = reData.collectionAttr;
          }else if(reData.claimSource === '票据台账'){
            _this.ruleFormRe.isBillFlag = true;
            _this.ruleFormRe.bankAccount = reData.bankAccount;
            _this.ruleFormRe.invoicePersonName = reData.invoicePersonName;
            _this.ruleFormRe.collectInvoiceMan = reData.collectInvoiceMan;
            _this.ruleFormRe.issueBillAccount = reData.issueBillAccount;
            _this.ruleFormRe.billDueDate = reData.billDueDate;
          }
          _this.ruleFormRe.claimAmount = Number(reData.collectionCurrencyAmount) - Number(reData.noClaimAmount);
        }
        if (saleData) {
          _this.ruleForm = saleData;
          _this.processInstanceName = util.getWorkflowCode(this.ruleForm.code, this.processInstanceName);
          _this.ruleForm.processInstanceId = saleData.processInstanceId;
          _this.ruleForm.orgCode = saleData.orgCode;
          _this.ruleForm.saleSubscriptionDetailList = saleData.saleSubscriptionDetailList;
          let line = _this.ruleForm.saleSubscriptionDetailList;
          let sumAmount = line.reduce(function (total, currentValue) {
            return parseFloat(total) + parseFloat(currentValue.useAmount);
          }, 0);
          for (let i = 0; i < line.length; i++) {
            if (line[i].availableAmount !== '') {
              if (parseFloat(line[i].availableAmount) === 0) {
                line[i].checkAmount = line[i].useAmount;
              } else {
                line[i].checkAmount = parseFloat(line[i].useAmount) + parseFloat(line[i].availableAmount);
              }
            }
          }
          if (parseFloat(_this.recordAmount) === 0) {
            // 如果剩余金额等于0 可变更金额不能大于使用金额
            _this.supAmount = sumAmount;
          } else {
            // 如果剩余金额大于0 可变更金额不能大约使用金额+剩余金额之和
            _this.supAmount = sumAmount + parseFloat(_this.recordAmount);
          }
        }
        _this.variables.activitiData = saleData;
        let arryNew = [];
        _this.ruleForm.domCollectionOverseaDetail.map((item, index) => {
          arryNew.push(Object.assign({}, item, { lineNumber: index + 1 }));
        });
        _this.ruleForm.domCollectionOverseaDetail = arryNew;
        let line = _this.ruleForm.saleSubscriptionDetailList;
        let costIdeAmount = line.reduce(function (total, currentValue) {
          return parseFloat(total) + parseFloat(currentValue.useAmount);
        }, 0);
        if (costIdeAmount === 0) {
          _this.detailDisable = false;
        }
        this.getAttachmentList();
        this.getProcessMappingId();
        this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
        if (this.$route.query.type) {
          this.approvalShow = true;
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('sale.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.code, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/sale/saleSubscriptionHeader/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('sale.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.code, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/sale/saleSubscriptionHeader/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/sale/saleSubscriptionHeader/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('sale.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/sale/saleSubscriptionHeader/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    this.functionalCurrency = util.getCurrencyName(this.ruleForm.orgId);
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      this.ruleForm.claimSource = selectRow.claimSource;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData();
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
